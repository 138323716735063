import React from 'react'
import Layout from '../components/Layout'

const ImpressumPage = () => (
  <Layout>
    <section className='hero is-primary'>
      <div className='hero-body subpage'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10'>
              <h2 className='is-size-2 anthracite-70-text capitalize page-heading'>
                Impressum
              </h2>
            </div>
            <div className='column is-2 anthracite-70-text text-center breadcrumb'>
              <span>{'HOME > Impressum'}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='yellow-border' />
    <section className='section section--gradient without-vertical-padding'>
      <div className='container anthracite-text'>
        <div className='section without-vertical-padding'>
          <div className='columns'>
            <div className='column is-6'>
              <div className='content'>
                <p className='is-size-5'>
                  Firma:  Elektro Bechert GmbH<br />
                  Geschäftsführer: Bernd Bechert<br />
                  Handwerkskammer Berlin<br />
                  Elektro-Innung Berlin<br />
                  HRB-Nummer:  55446<br />
                  UST-Steuernummer:  37/211/20053
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ImpressumPage
